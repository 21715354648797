body {
	margin: 0;
}

* {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

a {
	text-decoration: none;
	outline: none;
}

form:focus {
	outline: none;
}

button:focus {
	outline: none;
}

span:focus {
	outline: none;
}
div:focus {
	outline: none;
}

.react-toast-notifications__container {
	z-index: 100000 !important;
}